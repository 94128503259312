import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    parameters: {
      type: Object,
      default: function _default() {}
    },
    tableData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      }
    };
  },
  components: {
    // render函数渲染组件
    expandDom: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null
        }
      },
      render: function render(h, ctx) {
        var params = {
          row: ctx.props.row,
          index: ctx.props.index
        };
        if (ctx.props.column) params.column = ctx.props.column;
        return ctx.props.render(h, params);
      }
    }
  },
  mounted: function mounted() {},
  watch: {
    tableData: {
      handler: 'getList'
    }
  },
  methods: {
    // 解决重复id报错问题
    getRowkey: function getRowkey(row) {
      if (row.user_id) {
        return row.user_id;
      } else {
        return row.id;
      }
    },
    getList: function getList() {
      console.log(111, this.tableData);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.query.pagesize = val;
      this.getList(this.query.page, val);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.query.page = val;
      // this.getList(val, this.query.pagesize);
    }
  }
};